import React,{Component} from 'react';  
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import UX from '../../assets/Images/UX.svg'; 
import UI from '../../assets/Images/UI.svg'; 
import '../../assets/style/scss/carousel.scss';
// import './owl.css';  
export class Carousel extends Component {  
        render()  
        {  
          return (  
            <div className="meet-our-team-carousel">  
          <div class='container-fluid' >      
           <div className="row title" style={{marginBottom: "20px"}} >
                <h2>Problem Solvers</h2>            
           </div>  
       </div>  
       <div class='container-fluid' >            
        <OwlCarousel items={3}  
          className="owl-theme"  
          loop  
          nav 
          responsive={
            {
                '1':{
                    items: 1
                },
                '1025': {
                    items: 2
                }
            }
          } 
          margin={8} >  
           <div className="inner-item">
                <img  className="img" src= {UX} alt="UX"/>
                <h4>UX Designer</h4>
                <p>14 years experience in Digital Product Designing, User Experience and User Interface Designing,</p>
            </div>  
            <div className="inner-item">
                <img  className="img" src= {UI} alt="UX"/>
                <h4>Sr. UI Developer</h4>
                <p>Having 7 years of experience in HTML5,CSS 3, SCSS, BOOTSTRAP, Jquery, JScript, Gulp, Angular 8 version, React JS, Responsive Designs.</p>
            </div> 
            <div className="inner-item">
                <img  className="img" src= {UX} alt="UX"/>
                <h4>Full Stack Engineer</h4>
                <p>Experienced Full Stack Engineer with a demonstrated history of working in the internet industry. Skilled in Go lang, Express.js, GraphQL, Github, Node.js, and Jenkins. </p>
            </div>  
            <div className="inner-item">
                <img  className="img" src= {UI} alt="UX"/>
                <h4>Marketing Professional</h4>
                <p>I am a Marketing Professional with experience in the domain of Digital Marketing, Inbound Marketing, Social Media Management, Paid Ads and Content.</p>
            </div>  
      </OwlCarousel>  
      </div>  
  
      </div>  
          )  
        }  
      }  
        
  
export default Carousel 