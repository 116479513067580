import './App.css';
import Container from 'react-bootstrap/Container';
import Header from './components/Header/header';
import Banner from './components/Banner/banner';
import Services from './components/Services/services';
import Offers from './components/offers/offers';
import Carousel from './components/Carousel/Carousel';
import Feedback from './components/Feedback/feedback';
import Footer from './components/Footer/footer';
import Row from 'react-bootstrap/Row';

function App() {
  return (
    <div>
      <head>
      <title>Creative Web Design, Logo Design, Mobile App Design | Your Digital Marketing Partner</title>
      <meta charset="UTF-8" />
      <meta name="title" content="Creative Web Design, Logo Design, Mobile App Design | Your Digital Marketing Partner"></meta>
      <meta name="description" content="Techypeeps offers professional web design, logo design, mobile app design, brand design, and digital marketing services. Elevate your online presence with our expert social media marketing strategies" />
      <meta name="keywords" content="Web design, Logo design, Mobile app design, Brand design, Social media marketing, Digital marketing, Website development, Graphic design, User experience (UX) design, Search engine optimization (SEO), Content marketing, Online branding" />
      <meta name="author" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </head>
    <Container fluid>
        <Row>
          <Header />
          <Banner />
          <Services />
          <Offers />
          <Carousel />
          <Feedback />
          <Footer />
        </Row>
      </Container>
      </div>
  )
}

export default App;
